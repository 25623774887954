<template lang="pug">
    el-dialog( title="Angajat" top='20px' :visible.sync="showPopup" class="my-dialog-class" )
        el-form( v-if="!deConcediat" label-position="top" :inline="false" :rules="rules" label-width="100%"  :model='selectedObject'  ref='my-form' @submit.prevent="save" v-loading="loadingVisible" )
            el-row( :gutter="15" )
                el-col( :lg="8" :sm='12' :xs='12')
                    el-form-item( label="Nume" prop="Nume"  :required="true" )
                        el-input( size='small' v-model="selectedObject.Nume" )

                el-col( :lg="8" :sm='12' :xs='12')
                    el-form-item( label="Tag" prop="Tag"  :required="true" )
                        el-input( size='small'  v-model="selectedObject.Tag" )

                el-col( :lg="8" :sm='12' :xs='24')
                    el-form-item( label="Telefon" prop="Telefon"  )
                        el-input( size='small'  v-model="selectedObject.Telefon" )

                el-col( :lg="8" :sm='12' :xs='24')
                    el-form-item( label="Centru de cost" prop="IdCentruCost"  :required="true" )
                        el-select.full-width( size='small'  v-model="selectedObject.IdCentruCost" )
                            el-option( v-for="c in info.centre_cost" :key="'cc-' + c.Id" :label="c.Nume" :value="c.Id" )
                
                el-col( :lg="8" :sm='12' :xs='24')
                    el-form-item( label="Departament" prop="IdDepartament"  :required="true" )
                        el-select.full-width(  size='small' v-model="selectedObject.IdDepartament" )
                            el-option( v-for="c in info.departamente" :key="'d-' + c.Id" :label="c.Nume"  :value="c.Id")

                el-col( :lg="8" :sm='12' :xs='24')
                    el-form-item( label="Program" prop="IdProgram"  :required="true" )
                        el-select.full-width(  size='small' v-model="selectedObject.IdProgram" )
                            el-option( v-for="c in info.programe" :key="'p-' + c.Id" :label="c.Nume"  :value="c.Id")
            
            el-row( :gutter="15" )
                el-col( :lg="8" :sm='12' :xs='24')
                    el-form-item( label="Data incepere contract de munca" prop="DataIncepereContract" :required="true" )
                        el-tooltip( class="item" effect="dark" content="Poate fi data de 1 a anului curent, nu cea reala. " placement="bottom")
                            el-date-picker.full-width(  size='small' v-model="selectedObject.DataIncepereContract" value-format="yyyy-MM-dd" format="yyyy-MM-dd" )

                el-col( :lg="8" :sm='12' :xs='24')
                    el-form-item( label="Data nastere" prop="DataNastere"  )
                        el-date-picker.full-width(  size='small' v-model="selectedObject.DataNastere" value-format="yyyy-MM-dd" format="yyyy-MM-dd" )
                
                el-col( :lg="8" :sm='12' :xs='24')
                    el-form-item( label="CNP" prop="CNP"  )
                        el-input.full-width(  size='small' v-model="selectedObject.CNP" )
                
                el-col( :lg="8" :sm='12' :xs='24')
                    el-form-item( label="Numar marca" prop="NumarMarca"  )
                        el-input.full-width(  size='small' v-model="selectedObject.NumarMarca" )
                el-col(:lg="8" :sm='12' :xs='24')
                    el-form-item(label="Salariu net" prop="SalariuNet")
                        el-input.full-width(size='small' v-model="selectedObject.SalariuNet" @input="validateNumber($event,'SalariuNet')")

                el-col( :lg="8" :sm='12' :xs='24')
                    el-form-item( label="Nr Zile Concediu Contract" )
                        el-input.full-width(  size='small' v-model="selectedObject.NrZileConcediuContract" )

                el-col( :lg="8" :sm='12' :xs='24')
                    el-form-item( label="Nr Zile Concediu An In Curs" )
                        el-input.full-width(  size='small' v-model="selectedObject.NrZileConcediuAnInCurs" )

                el-col( :lg="8" :sm='12' :xs='24')
                    el-form-item( label="Nr Zile Concediu Reportate" )
                        el-input.full-width(  size='small' v-model="selectedObject.NrZileConcediuReportate" )
            
            el-row( :gutter="15" )
                el-col( :lg="8" :sm='12' :xs='24')
                    el-form-item(  label="Observatii" prop="Observatii"  )
                        el-input(  size='small' v-model="selectedObject.Observatii" )

                el-col( :lg="8" :sm='12' :xs='24')
                    el-form-item( label="Detalii Raport" prop="DetaliiRaport"  )
                        el-input( size='small'  v-model="selectedObject.DetaliiRaport" )
            
            el-row( :gutter="15" )
                el-col( :lg="8" )
                    el-form-item( label="Sterge angajat de la data de" prop="StersDeLaDataDe"  )
                        el-date-picker.full-width(  size='small' v-model="selectedObject.StersDeLaDataDe" value-format="yyyy-MM-dd" format="yyyy-MM-dd" )

        el-form( v-else label-position="top" :inline="false" :rules="rules" label-width="100%"  :model='selectedObject'  ref='my-form' @submit.prevent="save" v-loading="loadingVisible" )
            el-row( :gutter="15" )
                el-col( :lg="8" )
                    el-form-item( label="Sterge angajat de la data de" prop="StersDeLaDataDe"  )
                        el-date-picker.full-width(  size='small' v-model="selectedObject.StersDeLaDataDe" value-format="yyyy-MM-dd" format="yyyy-MM-dd" )


        span( slot="footer" class="dialog-footer" )
            el-button( @click="showPopup=false" ) Renunta
            el-button( type="primary" @click="save") Salveaza
</template>

<script>
import settings from "@/backend/LocalSettings";
import BaseComponent from '@/pages/BaseComponent';

export default {
  name: "angajati-dialog",
  extends: BaseComponent,
  data () {
    return { 
      showPopup: false,
      mode: 'add',
      deConcediat: false,
      selectedObject: { 
          Nume: "", Tag:"", Telefon:"", Observatii: "", DetaliiRaport:"", StersDeLaDataDe:"", DataNastere:"", CNP:"", NumarMarca:"", SalariuNet:"", 
          NrZileConcediuContract: 0, NrZileConcediuAnInCurs:0, NrZileConcediuReportate:0
        },
      info:{
          departamente: [],
          centre_cost:[],
          programe: []
      },
      rules: {
          Nume:             [ { required: true, message: "Numele este obligatoriu" } ],
          IdCentruCost:     [ { required: true, message: "Centrul de cost este obligatoriu" } ],
          IdDepartament:    [ { required: true, message: "Departamentul este obligatoriu" } ],
          IdProgram:        [ { required: true, message: "Programul este obligatoriu" } ],
          StersDeLaDataDe:  [ {required: false }],
          DataNastere:  [ {required: false }]
      }
    }
  },
  methods: {
    validateNumber(event, field) {
        
      let value = event;
      const regex = /^\d+(\.\d{0,2})?$/;

      if (!regex.test(value)) {
        value = value.replace(/[^0-9.]/g, '');
        const parts = value.split('.');
        if (parts.length > 2) {
          value = parts[0] + '.' + parts.slice(1).join('');
        }
        if (parts[1] && parts[1].length > 2) {
          value = parts[0] + '.' + parts[1].slice(0, 2);
        }
      }
      this.selectedObject[field] = value;
    },
     show_me: async function( id, deConcediat = false ) {
         this.showPopup        = true;
         this.deConcediat = false;
         if(deConcediat){
             this.deConcediat = true;
         }
        if( id == null ) 
        {
            this.mode = "add";
            this.selectedObject.Nume            = "";
            this.selectedObject.Tag             = "";
            this.selectedObject.Telefon         = "";
            this.selectedObject.Observatii      = "";
            this.selectedObject.DetaliiRaport   = "";
            this.selectedObject.StersDeLaDataDe = "";
            this.selectedObject.DataNastere     = "";
        }
        else
        {
            this.mode             = "edit";
            var            result = await this.post("get-angajati", { id: id } );
            this.selectedObject   = result.Item;
        }
    },
    async get_info(){
        var result = await this.post("angajati/get_info");
        this.info  = result.Info;
    },
    save: async function() {
        this.$refs['my-form'].validate( async(valid) => {
          if (valid) 
          {
            var response = await this.post("save-angajati", { mode: this.mode, object: this.selectedObject } );
            if( response.Error ) {
                 if(response.AngajatDeSchimbatTag){
                    this.confirmaSetTag(response)
                 }
            } else { 
                this.showPopup = false;
                this.$emit("save");
            }
          }
        });
    },
    confirmaSetTag: async function(response){
        var confirmed = await this.$confirm(response.MesajEroare + ", doriti sa continuati?", 'Warning' );
        if( confirmed )
        {
            await this.post("angajati/seteaza_tag", { UpdateAngajat: response.AngajatDeSchimbatTag,
                                                      UnsetAngajat: response.AngajatDeStersTag,
                                                      Mode: response.Mode} );
            this.showPopup = false;
        }
    }
  },
  mounted: function() {
      this.get_info();
  }
};
</script>

<style lang="less" scoped>
    
    
</style>